<template>
  <div
    class="modal fade"
    id="AddProductImageURLModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="AddImageUrlModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content addUrlModalContent" style="min-height: 30vh">
        <div class="container p-4">
          <div class="row px-3">
            <div
              class="w-100 d-flex justify-content-between align-items-center"
            >
              <div>
                <h4 class="font-weight-bold" style="color: #4d4950">
                  Add Image
                </h4>
              </div>
              <div>
                <button
                  @click="closeURLModal"
                  class="outline-none outline-none border-0 px-2 py-1 text-white"
                  style="
                    cusrsor: pointer;
                    border-radius: 6px;
                    background: #b3afb6;
                  "
                >
                  Cancel
                </button>
              </div>
            </div>

            <div class="col-12 mt-5">
              <div class="ImageURLInput d-flex align-items-center py-1">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  v-model="ImageUrlLink"
                  placeholder="Enter Image URL"
                />
              </div>
            </div>
            <div
              class="col-12 mt-3 d-flex align-items-center justify-content-center"
            >
              <button
                @click="uploadImageLink"
                class="outline-none outline-none border-0 px-3 py-2 text-white"
                :style="
                  ImageUrlLink.length > 0
                    ? 'background:#4D1B7E'
                    : 'background:#4D1B7Ea6; pointer-events: none;'
                "
                style="cusrsor: pointer; font-size: 16px; border-radius: 6px"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import $ from 'jquery'
export default {
  data() {
    return {
      isImageAdding: false,
      ImageUrlLink: "",
    };
  },
  computed: {
    getRandomValue() {
      return Math.floor(Math.random() * (1000 - 11 + 1) + 11);
    },
  },
  methods: {
    openModal() {
      $("#AddProductURLModal").modal("show");
    },
    closeModal() {
      $("#AddProductImageURLModal").modal("hide");
    },
    async uploadImageLink() {
      this.closeURLModal();
      this.isImageAdding = true;

      try {
        this.isLink = true;
        let data = new FormData();
        data.append("product_id", this.getRandomValue);
        data.append("isLink", true);
        data.append("link", this.ImageUrlLink);
        data.append("images", []);

        const response = await axios.post(`/uploadProductGallery`, data);

        if (response.status == 200) {
          this.imageLink = "";
          for (let image in response.data) {
            this.newProduct.productImages.push(response.data[image]);
          }
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isImageAdding = false;
      }
    },
  },
};
</script>
